<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('lead.wizard_campaign.general_data_title') }}</p>
        <p>{{ $t('lead.wizard_campaign.general_data_subtitle') }}</p>
      </div>
    </div>
    <campaign-form :campaign="campaign" ref="campaignForm"/>
  </div>
</template>

<script>
  import Campaign from "@/models/campaign";
  import CampaignForm from "@/pages/Leads/forms/CampaignForm";

  export default {
    name: "CampaignWizardFirstStep",
    components: {CampaignForm},
    data() {
      return {
        campaign: this.$_.cloneDeep(Campaign)
      }
    },
    methods: {
      validate() {
        return this.$refs.campaignForm.getCampaignData();
      }
    }
  }
</script>

<style scoped>

</style>
