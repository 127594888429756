<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('lead.wizard_campaign.third_step_info') }}</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <campaign-filter-list :filters="campaign.filters"/>
      </div>
      <div class="col-12 col-md-4" v-for="(card, index) in statsCards" :key="index">
        <stats-card
          class="shadow"
          :title="card.title"
          :sub-title="card.subTitle"
          :type="card.type"
          :icon="card.icon"
        >
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>

import StatsCard from "@/components/Cards/StatsCard";
import Campaign from "@/models/campaign";

import {endpoints} from "../../../routes/endpoints";
import CampaignFilterList from "../components/CampaignFilterList";

export default {
  name: "CampaignWizardThirdStep",
  components: {
    CampaignFilterList,
    StatsCard
  },
  props: {
    campaign: {
      type: Object,
      default: () => Campaign
    }
  },
  data() {
    return {
      endpoints: endpoints,
      statsCards: [],
    }
  },
  mounted() {
    if (this.campaign.report) {
      this.statsCards = [
        {
          title: String(this.campaign.report?.count),
          subTitle: this.$t('lead.wizard_campaign.lead_matches'),
          type: 'info',
          icon: 'checked'
        }
      ];
    }
  },
}
</script>

<style scoped>

</style>
