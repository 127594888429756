<template>
  <card footer-classes="text-right" class="shadow" body-classes="standard-card-body">
    <div slot="header">
      <h4 class="card-title text-capitalize">{{ $t('fields.filters') }}</h4>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item text-center" v-if="filtersAreMissing">
        {{ $t('callcenter.missing_filters') }}
      </li>
      <list-group-item-component v-if="filters.courses && filters.courses.length > 0" :label="$t('fields.courses')">
        <template slot="value">
          <div class="d-flex flex-column text-right">
            <div v-for="(course, index) in filters.courses" v-bind:key="`course-${index}`">
              <label-theme-component>{{ course }}</label-theme-component>
            </div>
          </div>
        </template>
      </list-group-item-component>
      <list-group-item-component
        v-if="filters.providers && filters.providers.length > 0"
        :label="$t('fields.providers')"
        :value="filters.providers.join(', ')"
      />
      <list-group-item-component
        v-if="filters.provinces && filters.provinces.length > 0"
        :label="$t('fields.provinces')"
        :value="filters.provinces.join(', ')"
      />
    </ul>
  </card>
</template>

<script>
import BaseAlert from "@/components/BaseAlert";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "CampaignFilterList",
  components: {LabelThemeComponent, ListGroupItemComponent, BaseAlert},
  props: {
    filters: {
      type: Object,
      default: () => ({
        courses: [],
        provinces: [],
        providers: []
      })
    }
  },
  computed: {
    filtersAreMissing: function () {
      return !this.filters?.courses?.length
        && !this.filters?.provinces?.length
        && !this.filters?.providers?.length;
    }
  }
}
</script>

<style scoped>

</style>
