<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('lead.wizard_campaign.third_step_info') }}</p>
      </div>
    </div>
    <div class="row justify-content-center mb-3">
      <div class="col-12 form-group">
        <p class="category">{{ $t('fields.courses') }}</p>
        <el-select
          class="select-default text-uppercase"
          :placeholder="$t('fields.choose')"
          v-model="filtersSelected.courses"
          multiple
          collapse-tags
        >
          <el-option
            v-for="option in filters.courses"
            class="select-default text-uppercase"
            :value="option.value"
            :label="option.label"
            :key="option.value"
          >
          </el-option>
        </el-select>
        <ul class="list-group list-group-flush mt-3">
          <li
            v-for="(item, indexCourse) in filtersSelected.courses" v-bind:key="indexCourse"
            class="list-group-item p-2 text-uppercase">
            {{getListItem('courses', item)}}
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 form-group">
        <p class="category">{{ $t('fields.providers') }}</p>
        <el-select
          class="select-default text-uppercase"
          :placeholder="$t('fields.choose')"
          v-model="filtersSelected.providers"
          multiple
          collapse-tags
        >
          <el-option
            v-for="option in filters.providers"
            class="select-default text-uppercase"
            :value="option.value"
            :label="option.label"
            :key="option.value"
          >
          </el-option>
        </el-select>
        <ul class="list-group list-group-flush mt-3">
          <li
            v-for="(item, indexProvider) in filtersSelected.providers" v-bind:key="indexProvider"
            class="list-group-item p-2 text-uppercase">
            {{getListItem('providers', item)}}
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-6 form-group">
        <p class="category">{{ $t('fields.provinces') }}</p>
        <el-select
          class="select-default text-uppercase"
          :placeholder="$t('fields.choose')"
          v-model="filtersSelected.provinces"
          multiple
          collapse-tags
          filterable
        >
          <el-option
            v-for="option in filters.provinces"
            class="select-default text-uppercase"
            :value="option.value"
            :label="option.label"
            :key="option.value"
          >
          </el-option>
        </el-select>
        <ul class="list-group list-group-flush mt-3">
          <li
            v-for="(item, indexProvince) in filtersSelected.provinces" v-bind:key="indexProvince"
            class="list-group-item p-2 text-uppercase">
            {{getListItem('provinces', item)}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import { Select, Option } from 'element-ui';

export default {
  name: "CampaignWizardSecondStep",
  components: {
    [Option.name]: Option,
    [Select.name]: Select
  },
  props: {
    filters: {
      type: Object,
      default: () => {
        return {
          'courses': [],
          'providers': [],
          'provinces': []
        }
      }
    }
  },
  data() {
    return {
      filtersSelected: {
        'courses': [],
        'providers': [],
        'provinces': []
      }
    }
  },
  methods: {
    getListItem(type, id) {
      const list = this.filters[type];
      const target = this.$_.find(list, (item) => {
        return item.value === id;
      })
      return target ? target.label : '';
    },
    getFilters() {
      return this.filtersSelected;
    }
  }
}
</script>

<style scoped>

</style>
