<template>
  <ValidationObserver ref="campaignForm">
    <div class="row">
      <div class="col-12 col-md-6">
        <ul class="list-group list-group-flush">
          <list-group-item-component :label="$t('fields.priority')" :label-width="labelWidth">
            <template slot="value">
              <base-input class="flex-grow-1 mt-3">
                <slider :range="{min: 0, max:10}"
                        :options="{step: 1, tooltips: true}"
                        v-model="campaignLocal.priority"
                />
              </base-input>
            </template>
          </list-group-item-component>

          <list-group-item-component :label="$t('fields.campaign_name')" :label-width="labelWidth">
            <template slot="value">
              <ValidationProvider
                class="flex-grow-1"
                name="campaign_name"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="campaignLocal.name"
                  class="mb-0"
                  type="text"
                  maxlength="50"
                  :placeholder="$t('fields.campaign_name')"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </template>
          </list-group-item-component>

          <list-group-item-component :label-width="labelWidth"
                                     :label="$t('fields.auto')"
                                     v-if="$route.name !== 'users.profile'"
          >
            <template slot="value">
              <base-input class="flex-grow-1 mb-0">
                <el-select
                  class="select-default text-uppercase"
                  :placeholder="$t('fields.choose')"
                  v-model="campaignLocal.auto"
                >

                  <el-option
                    v-for="option in [
                  { value: true, label: $t('common.yes')},
                  { value: false, label: $t('common.no')},
                ]"
                    class="select-default text-uppercase"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </template>
          </list-group-item-component>
          <list-group-item-component :label="$t('fields.origin')" :label-width="labelWidth">
            <template slot="value">
              <base-input class="flex-grow-1 mb-0">
                <el-select
                  clearable
                  class="select-default text-uppercase"
                  :placeholder="$t('fields.choose')"
                  v-model="campaignLocal.origin_id"
                >

                  <el-option
                    v-for="option in campaignsOptions"
                    class="select-default text-uppercase"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>

            </template>
          </list-group-item-component>
        </ul>
      </div>
      <div class="col-12 col-md-6">
        <ul class="list-group list-group-flush">
          <list-group-item-component :label="$t('fields.campaign_description')" :label-width="labelWidth">
            <template slot="value">
              <base-text-area
                class="flex-grow-1"
                :placeholder="$t('fields.campaign_description')"
                v-model="campaignLocal.description"
              />
            </template>
          </list-group-item-component>
        </ul>
      </div>
    </div>

  </ValidationObserver>
</template>

<script>
  import {Select, Option, DatePicker} from 'element-ui';
  import Campaign from "@/models/campaign";
  import Slider from "@/components/Slider";
  import ListGroupItemComponent from "@/components/ListGroupItemComponent";
  import BaseTextArea from "@/components/Inputs/BaseTextArea";
  import {mapGetters} from "vuex";
  import campaign from "@/models/campaign";

  export default {
    name: "CampaignForm",
    components: {
      BaseTextArea,
      ListGroupItemComponent,
      Slider,
      [Option.name]: Option,
      [Select.name]: Select,
      [DatePicker.name]: DatePicker,
    },
    data() {
      return {
        labelWidth: 200,
        campaignLocal: _.cloneDeep(Campaign),
      }
    },
    props: {
      campaign: {
        type: Object,
        default: () => _.cloneDeep(Campaign)
      }
    },
    mounted() {
      this.campaignLocal = this.$_.cloneDeep(this.campaign);
    },
    computed: {
      ...mapGetters({
        campaigns: 'common/campaigns',
      }),

      campaignsOptions() {
        return this.$_.map(
          this.$_.filter(this.campaigns, campaign => campaign.id !== this.campaignLocal.id),
          campaign => { return {label: campaign.name, value: campaign.id}
        });
      },
  },
    methods: {
      getCampaignData: function () {
        return new Promise((resolve, reject) => {
          this.$refs.campaignForm.validate()
            .then((success) => {
              if (!success) {
                reject();
              } else {
                resolve({
                  'name' : this.campaignLocal.name,
                  'priority' : this.campaignLocal.priority,
                  'description' : this.campaignLocal.description,
                  'origin_id' : this.campaignLocal.origin_id,
                  'auto'  : this.campaignLocal.auto,
                });
              }
            })
            .catch((err) => {
              reject(err)
            });
        });
      }
    }
  }
</script>

<style scoped>

</style>
