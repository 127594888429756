<template>
  <div class="row">
    <div class="col-md-12">
      <simple-wizard
        @tab-change="tabChange"
        @onCompleted="completeWizard"
        :finishButtonText="$t('common.finish')"
        :nextButtonText="$t('common.next')"
        :prevButtonText="$t('common.prev')"
      >
        <template slot="header">
          <h3 class="card-title">{{ $t('lead.wizard_campaign.title') }}</h3>
          <h3 class="description">{{ $t('lead.wizard_campaign.subtitle') }}</h3>
        </template>

        <wizard-tab :before-change="() => validateStep(1)">
          <template slot="label">
            <octo-icon icon="list"></octo-icon>
            <p>{{ $t('lead.wizard_campaign.general_data') }}</p>
          </template>
          <campaign-wizard-first-step ref="campaignWizardFirstStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(2)">
          <template slot="label">
            <octo-icon icon="filter"></octo-icon>
            <p>{{ $t('lead.wizard_campaign.filters') }}</p>
          </template>
          <campaign-wizard-second-step :filters="filters" ref="campaignWizardSecondStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(3)">
          <template slot="label">
            <octo-icon icon="checked"></octo-icon>
            <p>{{ $t('lead.wizard_campaign.report') }}</p>
          </template>
          <campaign-wizard-third-step :campaign="campaign" :key="campaignThirdStepKey"/>
        </wizard-tab>

      </simple-wizard>
    </div>
  </div>
</template>

<script>

import SimpleWizard from "@/components/Wizard/Wizard";
import WizardTab from "@/components/Wizard/WizardTab";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import CampaignWizardFirstStep from "@/pages/Leads/campaign-wizard/CampaignWizardFirstStep";
import {endpoints} from "@/routes/endpoints";
import CampaignWizardSecondStep from "@/pages/Leads/campaign-wizard/CampaignWizardSecondStep";
import CampaignWizardThirdStep from "@/pages/Leads/campaign-wizard/CampaignWizardThirdStep";
import Campaign from "@/models/campaign";
import {mapMutations} from "vuex";
import {MessageBox} from "element-ui";

export default {
  name: "CampaignWizardPage",
  components: {
    CampaignWizardThirdStep,
    CampaignWizardSecondStep, CampaignWizardFirstStep, OctoIcon, WizardTab, SimpleWizard
  },
  data() {
    return {
      campaign: this.$_.cloneDeep(Campaign),
      filters: {
        'courses': [],
        'providers': [],
        'provinces': []
      },
      campaignThirdStepKey: 0
    }
  },
  mounted() {
    this.$api.get(endpoints.CAMPAIGN_GET_FILTERS)
      .then((resp) => {
        this.filters = resp.data;
      }).catch(() => {
      this.$notify({type: 'danger', message: this.$t('notifications.loading_error')})
    })
  },
  methods: {
    ...mapMutations({
      updateCampaigns: 'common/updateCampaigns'
    }),

    async validateStep(step) {
      let check = false;

      switch (step) {
        case 1:
          const data = await this.$refs.campaignWizardFirstStep.validate();
          if (data) {
            this.campaign = data;
            check = true;
          }
          break;
        case 2:
        case 3:
          check = true;
          break;
        default:
          check = false;
          break;
      }

      if (!check) {
        this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
      }

      return check;
    },
    tabChange(oldTab, newTab) {
      switch (newTab.tabId) {
        case '0':
          break;
        case '1':
          this.$set(this.campaign, 'filters', this.$refs.campaignWizardSecondStep.getFilters());
          break;
        case '2':
          this.$set(this.campaign, 'report', []);
          break;
        default:
          break;
      }
    },
    completeWizard() {
      this.$api.post(endpoints.CAMPAIGN_STORE, this.campaign)
        .then((resp) => {
          this.updateCampaigns(resp.data);
          MessageBox({
            title: this.$t('common.good_job'),
            message: this.$t('lead.wizard_campaign.back_on_campaign_page'),
            confirmButtonText: this.$t('common.ok'),
            type: 'success'
          })
            .finally(() => {
              this.$router.push({name: 'leads.campaigns'});
            });
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })

    }
  }
}
</script>

<style scoped>

</style>
